<template>
  <div class="packshot-basketbutton">
    <b-button v-if="!packShotItemIds.includes(productId)" class="basketbutton" :title="basketButtonText" variant="custom" @click.stop="addToCart">
       <b-icon icon="b-bookmark" />
    </b-button>
      <b-button v-else class="basketbutton" :title="basketButtonRemoveText" variant="custom" @click.stop="removeFromCart">
       <b-icon icon="b-bookmark-check-fill" />
    </b-button>
  </div>
</template>

<script>

export default {
  name: 'PackshotListButton',
  props: {
    productId: {
      type: String,
      required: true
    },
    basketButtonText: {
      type: String,
      default: ''
    },
    basketButtonRemoveText: {
      type: String,
      default: ''
    }
  },
  methods: {
    addToCart () {
      this.$store.dispatch('packshotlistcart/addItem', { productId: this.productId })
    },
    removeFromCart () {
      this.$store.dispatch('packshotlistcart/removeItem', { itemId: this.productId, omitLastAction: true })
    }
  },
  computed: {
    packShotItemIds () {
      return this.$store.getters['packshotlistcart/get'].containedItemExternalIds
    }
  }
}
</script>

<style lang="scss">
$productbasketbutton-padding-y: $btn-padding-y * 0.4 !default;
$productbasketbutton-padding-x: $productbasketbutton-padding-y !default;
$productbasketbutton-bg: $primary !default;
$productbasketbutton-border: transparent !default;
$productbasketbutton-font-weight: $font-weight-bold !default;
$productbasketbutton-line-height: 1.1 !default;
$productbasketbutton-hover-bg: darken($productbasketbutton-bg, 5%) !default;
$productbasketbutton-hover-border: darken($productbasketbutton-border, 5%) !default;

$productbasketbutton-text-padding-y: 0 !default;
$productbasketbutton-text-padding-x: $spacer !default;

.packshot-basketbutton {
  position:absolute;
  top:0;
  right:0;
  width:45px;
  height:45px;

  .basketbutton {
    @include button-variant($productbasketbutton-bg, $productbasketbutton-border, $productbasketbutton-hover-bg, $productbasketbutton-hover-border);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $productbasketbutton-padding-y $productbasketbutton-padding-x;
    width: 100%;
    font-weight: $productbasketbutton-font-weight;
    line-height: $productbasketbutton-line-height;
    height:100%;
    width: 100%;
  }
}
</style>
